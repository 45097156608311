import { Link } from "gatsby"
import { H4, BodyRegular } from "../../../ui"
import { Container } from "./styled"

interface INoAvailabilityProps {
  acceptingNewPatients: boolean
  givenName: string
}

export const NoAvailability = ({
  acceptingNewPatients,
  givenName,
}: INoAvailabilityProps) => {
  const determineCopy = () => {
    if (!acceptingNewPatients) {
      return "isn't taking on new clients at the moment."
    } else {
      return "does not have any availability at the moment."
    }
  }

  return (
    <Container>
      <H4>{givenName} is unavailable</H4>
      <BodyRegular>
        Unfortunately {givenName} {determineCopy()}
      </BodyRegular>
      <BodyRegular>
        <Link to="/care-team">Let's find someone similar</Link>
      </BodyRegular>
    </Container>
  )
}
