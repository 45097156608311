import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import CarePersonPage from "../../components/CarePersonPage"
import { ICarePerson } from "../../components/CarePersonPage/types"

interface CarePersonInterface {
  data: {
    allSitePage: {
      edges: {
        node: {
          pageContext: ICarePerson
        }
      }[]
    }
  }
}

const CarePerson = ({ data }: CarePersonInterface) => {
  //pass down a single therapist to the page component
  const carePersonData = data.allSitePage.edges[0].node.pageContext
  return (
    <Layout>
      <CarePersonPage carePersonData={carePersonData} />
    </Layout>
  )
}

export const query = graphql`
  query ($path: String!) {
    allSitePage(filter: { path: { eq: $path } }) {
      edges {
        node {
          id
          path
          pageContext
        }
      }
    }
  }
`

export default CarePerson
