import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const GeneralCarePersonInfoContainer = styled.section`
  padding: 28px 0;
  display: flex;
  & > div {
    flex-direction: column;
    gap: 32px;
    padding-bottom: 56px;
    @media (max-width: 834px) {
      width: 100%;
    }
    @media (max-width: 600px) {
      gap: 24px;
      padding: 28px 24px;
    }
  }
`

export const GeneralInfoRow = styled.div`
  display: flex;
  column-gap: 88px;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 24px;
  }
`

export const GeneralInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const GeneralInfoItem = styled.div`
  > h2 {
    font-size: 16px;
    line-height: 24px;
  }
  > span {
    font-size: 16px;
    line-height: 28px;
    font-weight: 300;
  }
`
export const QuoteText = styled.p`
  font-family: "Poppins";
  font-size: 14px;
  font-style: italic;
  line-height: 24px;
  color: ${colors.c_primary[900]};
`
