import React, { useState, useLayoutEffect, useEffect } from "react"

interface windowSize {
  width: number
  isMobile: boolean
}

export const useWindowSize = (size = 834): windowSize => {
  const [width, setWidth] = useState<number>(0)
  const updateSize = (windowWidth: number) => setWidth(windowWidth)

  useEffect(() => {
    if (document.readyState === "complete") {
      updateSize(window.innerWidth)
    } else {
      window.addEventListener("load", () => updateSize(window.innerWidth))
      return () =>
        window.removeEventListener("load", () => updateSize(window.innerWidth))
    }
  }, [])

  useEffect(() => {
    window.addEventListener("resize", () => updateSize(window.innerWidth))
    return (): void => {
      window.removeEventListener("resize", () => updateSize(window.innerWidth))
    }
  }, [])

  return { isMobile: width <= size, width }
}
