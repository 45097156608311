import { H2 } from "../../../ui"
import {
  GeneralCarePersonInfoContainer,
  GeneralInfoItem,
  GeneralInfoRow,
  GeneralInfoColumn,
  QuoteText,
} from "./styled"
import { InnerContentContainer } from "../../styled"
import { ICarePerson } from "../../types"

interface GeneralInfoProps {
  carePersonData: ICarePerson
}
export const GeneralInfo = ({
  carePersonData,
}: GeneralInfoProps): JSX.Element => {
  const isTherapist = carePersonData.role === "Therapist"

  const showBio =
    !carePersonData.bio_approach_to_therapy &&
    !carePersonData.bio_experience &&
    !carePersonData.bio_hobbies_int_

  return (
    <GeneralCarePersonInfoContainer>
      <InnerContentContainer>
        {showBio && (
          <GeneralInfoRow>
            <GeneralInfoItem>
              <H2>Bio</H2>
              <span>{carePersonData.bio}</span>
            </GeneralInfoItem>
          </GeneralInfoRow>
        )}
        {carePersonData.bio_approach_to_therapy && (
          <GeneralInfoRow>
            <GeneralInfoItem>
              <H2>Approach to Therapy</H2>
              <span>{carePersonData.bio_approach_to_therapy}</span>
            </GeneralInfoItem>
          </GeneralInfoRow>
        )}
        {carePersonData.bio_experience && (
          <GeneralInfoRow>
            <GeneralInfoItem>
              <H2>Experience</H2>
              <span>{carePersonData.bio_experience}</span>
            </GeneralInfoItem>
          </GeneralInfoRow>
        )}
        {isTherapist && carePersonData.specialties?.length > 0 && (
          <GeneralInfoRow>
            <GeneralInfoItem>
              <H2>Specialties</H2>
              <span>{carePersonData.specialties.join(", ")}</span>
            </GeneralInfoItem>
          </GeneralInfoRow>
        )}
        {carePersonData.bio_hobbies_int_ && (
          <>
            <GeneralInfoRow>
              <GeneralInfoItem>
                <H2>Hobbies and Interests</H2>
                <span>{carePersonData.bio_hobbies_int_}</span>
              </GeneralInfoItem>
            </GeneralInfoRow>
            {carePersonData.quote && (
              <QuoteText>{`"${carePersonData.quote}"`}</QuoteText>
            )}
          </>
        )}
        <GeneralInfoRow>
          {carePersonData.gender && carePersonData.ethnicity && (
            <GeneralInfoColumn>
              {carePersonData.gender && (
                <GeneralInfoItem>
                  <H2>Gender</H2>
                  <span>{carePersonData.gender}</span>
                </GeneralInfoItem>
              )}
              {carePersonData.ethnicity?.length > 0 && (
                <GeneralInfoItem>
                  <H2>Ethnicity</H2>
                  <span>{carePersonData.ethnicity.join(", ")}</span>
                </GeneralInfoItem>
              )}
            </GeneralInfoColumn>
          )}
          {carePersonData.languages && carePersonData.licensed_states && (
            <GeneralInfoColumn>
              {isTherapist && carePersonData.languages?.length > 0 && (
                <GeneralInfoItem>
                  <H2>Language</H2>
                  <span>{carePersonData.languages.join(", ")}</span>
                </GeneralInfoItem>
              )}
              {carePersonData.licensed_states?.length > 0 && (
                <GeneralInfoItem>
                  <H2>Licensed in</H2>
                  <span>{carePersonData.licensed_states.join(", ")}</span>
                </GeneralInfoItem>
              )}
            </GeneralInfoColumn>
          )}
        </GeneralInfoRow>
      </InnerContentContainer>
    </GeneralCarePersonInfoContainer>
  )
}
