import styled from "styled-components"
import { colors } from "../../themes/colors"

export const TimeSlotsContainer = styled.div<{
  variant: "compact" | "expanded"
}>`
  display: grid;
  gap: ${({ variant }) => (variant === "compact" ? "8px" : "12px 16px")};
  grid-template-columns: ${({ variant }) =>
    variant === "compact" ? "96px 96px 96px" : "100px 100px 100px"};
`

const determineButtonColor = (
  selected: boolean,
  variant: "expanded" | "compact"
) => {
  if (variant === "compact") {
    if (selected) {
      return `
        color: ${colors.c_white[500]};
        border: 1px solid #49356e;
        background-color: #49356e;
      `
    }
    return `
      color: #49356e;
      border: 1px solid #98b1ff;
      background-color: ${colors.c_white[500]};
    `
  } else if (variant === "expanded") {
    if (selected) {
      return `
        color: ${colors.c_white[500]};
        background-color: ${colors.c_secondary[500]};
        border: 1px solid ${colors.c_white[500]};
        outline: 2px solid ${colors.c_secondary[200]};
        &:hover {
          border: 1px solid ${colors.c_white[500]} !important;
        }
      `
    }
    return `
      color: #49356e;
      border: 1px solid ${colors.c_secondary[200]};
      background-color: ${colors.c_white[500]};
    `
  }
}

export const TimeSlotButton = styled.button<{
  selected: boolean
  variant: "compact" | "expanded"
}>`
  height: ${({ variant }) => (variant === "compact" ? "32px" : "40px")};
  border-radius: ${({ variant }) => (variant === "compact" ? "50px" : "30px")};
  font-weight: 600;
  font-size: ${({ variant }) => (variant === "compact" ? "14px" : "12px")};
  line-height: 24px;
  cursor: pointer;
  font-family: "Poppins";
  ${({ selected, variant }) => determineButtonColor(selected, variant)}
  &:hover {
    border-color: ${colors.c_primary[600]};
  }
`
