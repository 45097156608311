import { useWindowSize } from "../../../hooks"
import { ICarePerson } from "../../types"
import {
  AvailabilityHeadingMobile,
  CalendarTitle,
  InnerContentContainer,
  BookButton,
  FooterText,
  SelectedDateText,
} from "./styled"
import { handleBook } from "../../../utils/utils"
import { Calendar } from "../../../shared/Calendar"
import { TimeSlots } from "../../../shared/TimeSlots"
import { useCarePersonAvailability } from "../../../shared/hooks/useCarePersonAvailability"
import { NoAvailability } from "../NoAvailability"
import Spinner from "../../../ui/Spinner"

interface AvailabilityProps {
  carePersonData: ICarePerson
}

export const Availability = ({
  carePersonData,
}: AvailabilityProps): JSX.Element => {
  const { isMobile } = useWindowSize()

  const { accepting_new_patients, given_name } = carePersonData

  const {
    availableDates,
    timeSlots,
    timeSelected,
    setTimeSelected,
    dateString,
    handleChangeDate,
    hasBeenLoaded,
  } = useCarePersonAvailability(carePersonData)

  const disableCalendar =
    !accepting_new_patients || (hasBeenLoaded && !availableDates.length)

  return (
    <InnerContentContainer>
      {isMobile && (
        <AvailabilityHeadingMobile>
          Think {given_name} sounds like a great fit?
        </AvailabilityHeadingMobile>
      )}
      <CalendarTitle>{given_name}'s availability</CalendarTitle>
      {hasBeenLoaded ? (
        <Calendar
          calendarVersion="availability"
          availableDays={availableDates}
          handleChangeDate={handleChangeDate}
          disabled={disableCalendar}
        />
      ) : (
        <Spinner className="" />
      )}
      {disableCalendar ? (
        <NoAvailability
          acceptingNewPatients={accepting_new_patients}
          givenName={given_name}
        />
      ) : (
        <>
          <SelectedDateText>{dateString}</SelectedDateText>
          <TimeSlots
            timeSlots={timeSlots}
            timeSelected={timeSelected}
            setTimeSelected={setTimeSelected}
          />
          <BookButton
            onClick={() => handleBook(carePersonData, timeSelected)}
            disabled={!timeSelected}
          >
            Book session
          </BookButton>
          <FooterText>
            If you don't see a suitable time, contact{" "}
            <a href="mailto:support@cerebral.com">support@cerebral.com</a>
          </FooterText>
        </>
      )}
    </InnerContentContainer>
  )
}
