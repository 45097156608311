import styled from "styled-components"
import { colors } from "../../../themes/colors"

export const CarePersonHeadingContainer = styled.section`
  background: transparent;
  display: flex;
  width: 100%;
  padding: 0 0px 28px;
  & > div {
    width: 100%;
    justify-content: space-between;
    padding-bottom: 40px;

    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
      padding: 88px 0px 28px;
    }
  }
`

export const CarePersonHeadingContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  h1 {
    margin-bottom: 13px;
  }
  & > div:last-child {
    font-weight: 300;
    margin-top: 8px;
    font-size: 14px;
    gap: 80px;
    flex-wrap: wrap;
    color: ${colors.c_black[500]};
    line-height: 24px;
    @media (max-width: 996px) {
      gap: 20px;
      flex-direction: column;
    }
    & > strong {
      font-weight: 500;
    }
    & > span > strong {
      font-weight: 500;
    }
  }

  @media (max-width: 600px) {
    order: 2;
    text-align: center;
    min-height: 142px;
    margin-top: 16px;
  }
`
