import { H1 } from "../../../ui"
import gendersvg from "../../../../images/Therapists-bio/gender-nuetral.svg"

import {
  CarePersonHeadingContainer,
  CarePersonHeadingContentContainer,
} from "./styled"
import { InnerContentContainer } from "../../styled"
import { ICarePerson } from "../../types"

interface Heading {
  hasCarePersonInfo: boolean
  carePersonData: ICarePerson
}
export const Heading = ({
  hasCarePersonInfo,
  carePersonData: carePersonData,
}: Heading): JSX.Element => {
  const isPrescriber = carePersonData.role === "Prescriber"
  return (
    <CarePersonHeadingContainer>
      <InnerContentContainer showBorder={!!hasCarePersonInfo}>
        <CarePersonHeadingContentContainer>
          <H1>{carePersonData.name}</H1>
          <span>{carePersonData.role}</span>
          {isPrescriber && <span> | {carePersonData.degrees.join(", ")}</span>}
        </CarePersonHeadingContentContainer>
        <img
          alt=""
          src={
            carePersonData.profile_photo === null ||
            carePersonData.profile_photo === ""
              ? gendersvg
              : carePersonData.profile_photo
          }
          height={173}
          width={173}
          loading="lazy"
        />
      </InnerContentContainer>
    </CarePersonHeadingContainer>
  )
}
