import { TimeSlotButton, TimeSlotsContainer } from "./styled"

interface ITimeSlotsProps {
  timeSlots: string[]
  timeSelected: string
  setTimeSelected: (selectedTime: string) => void
  variant?: "compact" | "expanded"
}

export const TimeSlots = (props: ITimeSlotsProps) => {
  const {
    timeSlots,
    timeSelected,
    setTimeSelected,
    variant = "compact",
  } = props

  const formatTime = (time: string) => {
    return new Date(time).toLocaleTimeString("en-us", {
      minute: "numeric",
      hour: "numeric",
      hour12: true,
    })
  }

  return (
    <TimeSlotsContainer variant={variant}>
      {timeSlots.map(time => {
        return (
          <TimeSlotButton
            onClick={() => setTimeSelected(time)}
            selected={time === timeSelected}
            variant={variant}
          >
            {formatTime(time)}
          </TimeSlotButton>
        )
      })}
    </TimeSlotsContainer>
  )
}
