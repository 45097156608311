import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const Container = styled.div`
  padding: 5% 8%;
  background: linear-gradient(
    ${colors.c_white[500]},
    #eff2ff 89%,
    ${colors.c_white[500]}
  );
  text-align: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
`

export const Card = styled.div`
  width: 100%;
  background-color: ${colors.c_white[500]};
  box-shadow: 0px 0px 12px rgba(81, 69, 158, 0.16);
  border-radius: 8px;
  margin: 32px auto;
  max-width: 800px;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  padding: 32px;
  min-height: 463px;
  box-sizing: border-box;
`

export const BookButton = styled.button`
  padding: 0px 24px;
  width: 200px;
  height: 56px;
  background: ${colors.c_primary[600]};
  color: ${colors.c_white[500]};
  border-radius: 28px;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  border: none;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 320px;
`

export const NoSuitableTimeText = styled.p`
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #535353;
  max-width: 300px;
  margin: 16px 0;
`
