import { TimeSlots } from "../../../shared/TimeSlots"
import { Calendar } from "../../../shared/Calendar"
import { GetStartedButton } from "../../../shared"
import {
  Container,
  Card,
  BookButton,
  ButtonContainer,
  RightColumn,
  NoSuitableTimeText,
} from "./styled"
import { ICarePerson } from "../../types"
import { H2, BodyRegular, H4 } from "../../../ui"
import { useCarePersonAvailability } from "../../../shared/hooks/useCarePersonAvailability"
import { handleBook } from "../../../utils/utils"

interface IExpandedAvailabilityProps {
  carePersonData: ICarePerson
}

export const ExpandedAvailability = ({
  carePersonData,
}: IExpandedAvailabilityProps) => {
  const {
    availableDates,
    timeSlots,
    timeSelected,
    setTimeSelected,
    handleChangeDate,
    hasBeenLoaded,
  } = useCarePersonAvailability(carePersonData)

  const { accepting_new_patients } = carePersonData

  const disableCalendar =
    !accepting_new_patients || (hasBeenLoaded && !availableDates.length)

  if (disableCalendar) return null

  return (
    <Container>
      <H2>Think {carePersonData.given_name} sounds like a great fit?</H2>
      <BodyRegular>Sign up or book your first appointment below</BodyRegular>
      <ButtonContainer>
        <GetStartedButton skipPresignup={true} width={220.57} />
      </ButtonContainer>
      <Card>
        <div>
          <H4>{carePersonData.name}'s availability</H4>
          <Calendar
            removeShadow
            calendarVersion="availability"
            availableDays={availableDates}
            handleChangeDate={handleChangeDate}
          />
        </div>
        <RightColumn>
          <TimeSlots
            timeSlots={timeSlots}
            timeSelected={timeSelected}
            setTimeSelected={setTimeSelected}
            variant="expanded"
          />
          <div>
            <BookButton
              onClick={() => handleBook(carePersonData, timeSelected)}
              disabled={!timeSelected}
            >
              Book a session today
            </BookButton>
            <NoSuitableTimeText>
              If you don’t see a suitable time, contact{" "}
              <a href="mailto:support@cerebral.com">support@cerebral.com</a>
            </NoSuitableTimeText>
          </div>
        </RightColumn>
      </Card>
    </Container>
  )
}
