import { colors } from "../../../themes/colors"
import styled from "styled-components"

export const CalendarTitle = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #222128;
  margin-bottom: 24px;
`
export const AvailabilityHeadingMobile = styled.p`
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #222128;
  max-width: 240px;
  padding-bottom: 40px;
`

export const InnerContentContainer = styled.div`
  width: 75%;
  align-items: center;
  display: flex;
  max-width: 704px;
  min-width: 275px;
  flex-direction: column;
  margin: 0 auto;
  @media (max-width: 1024px) {
    width: 80%;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const BookButton = styled.button`
  width: 140.06px;
  height: 56px;
  background: ${colors.c_primary[600]};
  border-radius: 28px;
  color: ${colors.c_white[500]};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-top: 40px;
  cursor: pointer;
  &:disabled {
    opacity: 0.5;
  }
`

export const FooterText = styled.p`
  color: #222128;
  text-align: center;
  font-family: "Inter";
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  a {
    color: ${colors.c_secondary[600]};
    text-decoration: underline;
  }
`

export const SelectedDateText = styled.p`
  margin: 16px 0 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: ${colors.c_primary[900]};
`
