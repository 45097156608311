import styled from "styled-components"
import { colors } from "../themes/colors"

export const CarePersonBody = styled.main`
  display: flex;
  background: ${colors.c_white[500]};
  background: linear-gradient(
    180deg,
    rgba(242, 245, 255, 1) 26%,
    rgba(255, 255, 255, 1) 33%
  );
  @media (max-width: 600px) {
    flex-direction: column;
    background: linear-gradient(
      180deg,
      rgba(242, 245, 255, 1) 18%,
      rgba(255, 255, 255, 1) 23%
    );
  }
`

export const CarePersonInfo = styled.div`
  display: grid;
  grid-template-columns: minmax(300px, 3fr) 1fr;
  margin: 120px auto 40px;
  padding: 0 12%;
  width: 100%;
  gap: 80px;
  box-sizing: border-box;
  @media (max-width: 600px) {
    margin-top: 0px;
    padding: 8px;
  }
  @media (max-width: 834px) {
    grid-template-columns: 1fr;
  }
`

export const LeftColumn = styled.div``

export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

interface InnerContainerProps {
  showBorder?: boolean
}
export const InnerContentContainer = styled.div<InnerContainerProps>`
  display: flex;
  margin: 0;
  box-sizing: border-box;
  border-bottom: ${({ showBorder }) =>
    showBorder ? "1px solid #D2D5E7" : "0"};
  width: 100%;
  @media (max-width: 600px) {
    margin: 0 auto;
  }
`

export const PlantContainer = styled.div`
  position: relative;
  min-height: 268px;
  & > img {
    position: absolute;
    bottom: 0;
    right: 64px;
    @media (max-width: 600px) {
      right: 32px;
    }
  }
`
