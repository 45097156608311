import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
  margin-top: 40px;
  p {
    font-size: 14px;
  }

  p,
  h4 {
    margin: 8px 0;
  }
`
